import styles from "./SmallButton.module.scss";

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const THEMES = { NAV: "NAV", SIMPLE: "SIMPLE", XSMALL: "XSMALL" };

function SmallButton({
  background,
  classNameForGTM,
  label,
  href,
  noBorder,
  theme,
  onClick,
  newTab,
}) {
  let style, styleLabel;
  switch (theme) {
    case THEMES.SIMPLE:
      style = {
        background,
        border: noBorder ? 0 : 1,
        borderColor: "#004b78",
        borderStyle: "solid",
        height: "unset",
        padding: "11px",
        color: "#004b78",
      };
      styleLabel = {
        fontSize: "14px",
      };
      break;
    case THEMES.XSMALL:
      style = {
        border: noBorder ? 0 : 1,
        borderColor: "#004b78",
        borderStyle: "solid",
        height: "unset",
        padding: "6px 12px",
        color: "#004b78",
        background,
      };
      styleLabel = {
        fontSize: "14px",
      };
      break;
    case THEMES.NAV:
      style = {
        border: noBorder ? 0 : 1,
        borderColor: "white",
        borderStyle: "solid",
        height: "unset",
        padding: "6px 12px",
        color: "white",
        background,
        marginTop: 12,
      };
      styleLabel = {
        fontSize: "14px",
        color: "white",
      };
      break;
    default:
      style = {
        border: noBorder ? 0 : 2,
        borderStyle: "solid",
        color: "#004b78",
        background,
      };
  }
  return (
    <a
      className={classNames(styles["container"])}
      href={href}
      onClick={onClick}
      rel={newTab ? "noopener noreferrer" : ""}
      style={style}
      target={newTab ? "_blank" : ""}
    >
      <span
        className={classNames(styles["label"], classNameForGTM)}
        style={styleLabel}
      >
        {label}
      </span>
    </a>
  );
}

SmallButton.THEMES = THEMES;

SmallButton.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  background: PropTypes.string,
  classNameForGTM: PropTypes.string,
  newTab: PropTypes.bool,
  noBorder: PropTypes.bool,
  onClick: PropTypes.func,
  theme: PropTypes.string,
};

SmallButton.defaultProps = {
  background: "transparent",
  classNameForGTM: "",
  newTab: false,
  noBorder: false,
  onClick: void 0,
  theme: void 0,
};

export default SmallButton;
