import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

function SEO({
  description,
  metaDescription,
  lang,
  meta,
  keywords,
  title,
  image,
  socialTitle,
  location,
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescriptionSocial =
          description || data.site.siteMetadata.description;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            meta={[
              {
                name: "description",
                content: metaDescription || metaDescriptionSocial,
              },
              {
                property: "og:title",
                content: socialTitle || title,
              },
              {
                property: "og:description",
                content: metaDescriptionSocial,
              },
              {
                property: "og:type",
                content: "website",
              },
              {
                property: "og:image",
                content: image,
              },
              {
                name: "twitter:card",
                content: image ? "summary_large_image" : "summary",
              },
              {
                name: "twitter:creator",
                content: data.site.siteMetadata.author,
              },
              {
                name: "twitter:title",
                content: socialTitle || title,
              },
              {
                name: "twitter:description",
                content: metaDescriptionSocial,
              },
              {
                name: "twitter:image",
                content: image,
              },
            ]
              .filter((e) => e.content)
              .concat(
                keywords.length > 0
                  ? {
                      name: "keywords",
                      content: keywords.join(", "),
                    }
                  : []
              )
              .concat(meta)}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
          >
            <script>
              {`
                var _hsq = window._hsq = window._hsq || [];
                _hsq.push(['setPath','${location.pathname}']);
                _hsq.push(['trackPageView']);
              `}
            </script>
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  description: "",
  image: undefined,
  keywords: [],
  lang: "en",
  meta: [],
  metaDescription: "",
  socialTitle: undefined,
};

SEO.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.string),
  metaDescription: PropTypes.string,
  socialTitle: PropTypes.string,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
