import styles from "./Header.module.scss";

import { Link } from "gatsby";
import React, { Component, createRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import Menu from "./menu/Menu";

import addI18nContextConsumer from "../../i18n/addI18nContextConsumer";

import logo from "../../../images/Icon/BusinessLocator/color@10x.png";
import whiteLogo from "../../../images/Icon/BusinessLocator/white@10x.png";

const THEMES = {
  TRANSPARENT: "transparent",
  NORMAL: "normal",
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.prevScrollpos = 0;
    this.navbar = createRef();
    this.state = {
      theme: THEMES.TRANSPARENT,
      openMenu: false,
    };
  }

  componentDidMount() {
    this.prevScrollpos = window.pageYOffset;
    window.addEventListener("scroll", this.callbackScrollBackEvent);
  }

  shouldComponentUpdate() {
    return true;
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.callbackScrollBackEvent);
  }

  callbackScrollBackEvent = () => {
    const { theme, openMenu } = this.state;
    if (openMenu) {
      return;
    }
    const currentScrollPos = window.pageYOffset;
    if (!this.navbar.current) return;
    if (this.prevScrollpos > currentScrollPos || currentScrollPos <= 0) {
      this.navbar.current.style.top = "0";
    } else {
      this.navbar.current.style.top = "-60px";
    }

    if (currentScrollPos <= 0) {
      this.setState({ theme: THEMES.TRANSPARENT });
    } else if (theme === THEMES.TRANSPARENT) {
      setTimeout(() => {
        this.setState({ theme: THEMES.NORMAL });
      }, 300);
    }

    this.prevScrollpos = currentScrollPos;
  };

  setOpenMenu = (openMenu) => {
    let { theme } = this.state;
    if (openMenu) {
      theme = THEMES.NORMAL;
    } else {
      var currentScrollPos = window.pageYOffset;
      if (!this.prevScrollpos || this.prevScrollpos >= currentScrollPos) {
        theme = THEMES.TRANSPARENT;
      }
    }
    this.setState({ openMenu, theme });
  };

  render() {
    const { blue, locale } = this.props;
    const { theme, openMenu } = this.state;
    return (
      <nav
        ref={this.navbar}
        className={classNames(styles["container"], styles[theme])}
      >
        <Link
          className={classNames(styles["logo"], styles[theme], {
            [styles["white"]]: !blue,
          })}
          to={`/${locale}/`}
        >
          <img
            alt="BusinessLocator logo"
            height={40}
            src={!blue && theme === THEMES.TRANSPARENT ? whiteLogo : logo}
          />
        </Link>
        <div className={styles["menu"]}>
          <Menu
            blueTheme={blue || theme === THEMES.NORMAL}
            openMenu={openMenu}
            setOpenMenu={this.setOpenMenu}
          />
        </div>
      </nav>
    );
  }
}

Header.THEMES = THEMES;

Header.propTypes = {
  locale: PropTypes.string.isRequired,
  blue: PropTypes.bool,
};

Header.defaultProps = {
  blue: false,
};

export default addI18nContextConsumer()(Header);
