import styles from "./Page.module.scss";

import React from "react";
import PropTypes from "prop-types";

import Header from "./header/Header";
import Footer from "./footer/Footer";

function Page({ children, blue, noHeader }) {
  return (
    <div className={styles["container"]}>
      {!noHeader && <Header blue={blue} />}
      <div>{children}</div>
      <Footer />
    </div>
  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  blue: PropTypes.bool,
  noHeader: PropTypes.bool,
};

Page.defaultProps = {
  blue: false,
  noHeader: false,
};

export default Page;
