import React from "react";

import i18nContext from "./i18n.context";

export default function addI18nContextConsumer(i18n) {
  return (WrappedComponent) =>
    ({ ...props }) =>
      (
        <i18nContext.Consumer>
          {({ locale }) => (
            <WrappedComponent
              strings={i18n && i18n[locale]}
              {...{ locale, ...props }}
            />
          )}
        </i18nContext.Consumer>
      );
}
