import styles from "./Footer.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import SelectLanguage from "../../select-language/SelectLanguage";

import i18n from "./Footer.i18n.json";
import addI18nContextConsumer from "../../i18n/addI18nContextConsumer";

import { BLOG_URL, BLOG_URL_EN } from "../../../conf/conf";

function Footer({ strings, locale }) {
  return (
    <footer className={styles["container"]}>
      <div className={styles["content"]}>
        <div className={styles["links"]}>
          <div className={styles["link_block"]}>
            <Link className="Link-Footer-Home" to={`/${locale}/`}>
              {strings["home"]}
            </Link>
            <Link className="Link-Footer-FAQ" to={`/${locale}/faq`}>
              {strings["faq"]}
            </Link>
          </div>
          <div className={styles["link_block"]}>
            <Link className="Link-Footer-Features" to={`/${locale}/features`}>
              {strings["features"]}
            </Link>
            <Link className="Link-Footer-Legals" to={`/${locale}/legals`}>
              {strings["legals"]}
            </Link>
          </div>
          <div className={styles["link_block"]}>
            <Link className="Link-Footer-Pricing" to={`/${locale}/pricing`}>
              {strings["pricing"]}
            </Link>
            <Link className="Link-Footer-Cookies" to={`/${locale}/cookies`}>
              {strings["cookies"]}
            </Link>
          </div>
          <div className={styles["link_block"]}>
            <Link
              className="Link-Footer-Tutorials"
              to={`/${locale}/integration`}
            >
              {strings["tutorials"]}
            </Link>
            <Link
              className="Link-Footer-Confidentiality"
              to={`/${locale}/confidentiality`}
            >
              {strings["privacy"]}
            </Link>
          </div>
          <div className={styles["link_block"]}>
            <a className="Link-Footer-Subscribe" href="/app">
              {strings["account"]}
            </a>
            <Link className="Link-Footer-Terms" to={`/${locale}/terms`}>
              {strings["terms"]}
            </Link>
          </div>
          <div className={styles["link_block"]}>
            <Link
              className="Link-Footer-Blog"
              target="_blank"
              to={locale === "en" ? BLOG_URL_EN : BLOG_URL}
            >
              {strings["blog"]}
            </Link>
          </div>
        </div>
        <div className={styles["lang_credit"]}>
          <div>
            <SelectLanguage />
          </div>
          <div className={styles["text"]}>
            {strings["credit"]}{" "}
            <a className="Link-Footer-Takima" href="https://www.takima.fr">
              Takima
            </a>
          </div>
        </div>
      </div>
      <div className={styles["filler"]} />
    </footer>
  );
}

Footer.propTypes = {
  locale: PropTypes.string.isRequired,
  /*eslint-disable react/forbid-prop-types*/
  strings: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
};

export default addI18nContextConsumer(i18n)(Footer);
