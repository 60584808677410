import styles from "./SelectLanguage.module.scss";

import React from "react";
import { PropTypes } from "prop-types";
import { navigate } from "gatsby";

import addI18nContextConsumer from "../i18n/addI18nContextConsumer";

function SelectLanguage({ locale }) {
  return (
    <select
      className={styles["container"]}
      onChange={(e) => {
        const locale = e.target.value;
        const oldLocale = locale === "fr" ? "en" : "fr";
        const newPath = window.location.pathname.replace(
          `/${oldLocale}/`,
          `/${locale}/`
        );
        navigate(newPath);
      }}
      value={locale}
    >
      <option value="fr">Français</option>
      <option value="en">English</option>
    </select>
  );
}

SelectLanguage.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default addI18nContextConsumer()(SelectLanguage);
