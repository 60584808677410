import React from "react";
import PropTypes from "prop-types";

import MenuDesktop from "./desktop/MenuDesktop";
import MenuMobile from "./mobile/MenuMobile";

const Menu = ({ blueTheme, openMenu, setOpenMenu }) => {
  return (
    <>
      <MenuMobile
        blueTheme={blueTheme}
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
      />
      <MenuDesktop
        blueTheme={blueTheme}
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
      />
    </>
  );
};

Menu.propTypes = {
  openMenu: PropTypes.bool.isRequired,
  setOpenMenu: PropTypes.func.isRequired,
  blueTheme: PropTypes.bool,
};

Menu.defaultProps = {
  blueTheme: false,
};

export default Menu;
