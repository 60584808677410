import styles from "./MenuDesktop.module.scss";

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Link } from "gatsby";
import SmallButton from "../../../../button/small-button/SmallButton";

// import userBlueIcon from "../../../../../images/icons/user_blue.svg";
// import userWhiteIcon from "../../../../../images/icons/user_white.svg";

import i18n from "./MenuDesktop.i18n.json";
import addI18nContextConsumer from "../../../../i18n/addI18nContextConsumer";

import { BLOG_URL, BLOG_URL_EN } from "../../../../../conf/conf";

const PAGES = {
  HOME: "HOME",
  FEATURES: "FEATURES",
  PRICING: "PRICING",
  CONTACT: "CONTACT",
  FAQ: "FAQ",
  INTEGRATION: "INTEGRATION",
};

class MenuDesktop extends Component {
  shouldComponentUpdate() {
    return true;
  }

  getPage = () => {
    const { locale } = this.props;
    const pathname = typeof window !== "undefined" && window.location.pathname;
    switch (pathname) {
      case `/${locale}/`:
        return PAGES.HOME;
      case `/${locale}/features`:
        return PAGES.FEATURES;
      case `/${locale}/pricing`:
        return PAGES.PRICING;
      case `/${locale}/contact`:
        return PAGES.CONTACT;
      case `/${locale}/faq`:
        return PAGES.FAQ;
      case `/${locale}/integration`:
        return PAGES.INTEGRATION;
      default:
        return;
    }
  };

  render() {
    const { blueTheme, strings, locale } = this.props;
    const currentPage = this.getPage();

    // const smallScreen =
    //   typeof window !== "undefined" &&
    //   window.matchMedia("(max-width: 768px)").matches;

    return (
      <div className={styles["container"]}>
        <div
          className={classNames(styles["menu_container"], {
            [styles["blue"]]: blueTheme,
          })}
        >
          <div className={styles["menu"]}>
            <Link
              className={classNames(styles["link"], "Link-Header-Home", {
                [styles["blue"]]: blueTheme,
                [styles["selected"]]: currentPage === PAGES.HOME,
              })}
              to={`/${locale}/`}
            >
              {strings["home"]}
            </Link>
            <Link
              className={classNames(styles["link"], "Link-Header-Features", {
                [styles["blue"]]: blueTheme,
                [styles["selected"]]: currentPage === PAGES.FEATURES,
              })}
              to={`/${locale}/features`}
            >
              {strings["features"]}
            </Link>
            <Link
              className={classNames(styles["link"], "Link-Header-Pricing", {
                [styles["blue"]]: blueTheme,
                [styles["selected"]]: currentPage === PAGES.PRICING,
              })}
              to={`/${locale}/pricing`}
            >
              {strings["prices"]}
            </Link>
            <Link
              className={classNames(styles["link"], "Link-Header-Contract", {
                [styles["blue"]]: blueTheme,
                [styles["selected"]]: currentPage === PAGES.CONTACT,
              })}
              to={`/${locale}/contact`}
            >
              {strings["contact"]}
            </Link>
            <Link
              className={classNames(styles["link"], "Link-Header-FAQ", {
                [styles["blue"]]: blueTheme,
                [styles["selected"]]: currentPage === PAGES.FAQ,
              })}
              to={`/${locale}/faq`}
            >
              {strings["faq"]}
            </Link>
            <Link
              className={classNames(styles["link"], "Link-Header-Blog", {
                [styles["blue"]]: blueTheme,
              })}
              target="_blank"
              to={locale === "en" ? BLOG_URL_EN : BLOG_URL}
            >
              {strings["blog"]}
            </Link>
            {/* <Link
            className={classNames(styles["link"], "Link-Header-Integration", {
              [styles["blue"]]: blueTheme,
              [styles["selected"]]: currentPage === PAGES.INTEGRATION
            })}
            to={`/${locale}/integration`}
          >
            {strings["integration"]}
          </Link> */}
            {/* <a
              className={classNames(styles["lab_icon_user"], {
                [styles["blue"]]: blueTheme
              })}
              href=""
            >
              <img
                alt="icon user"
                src={blueTheme ? userBlueIcon : userWhiteIcon}
              />
            </a> */}
          </div>
          <div className={styles["action"]}>
            <SmallButton
              background="#0094ff"
              classNameForGTM="Link-Header-Subscribe"
              href="/app"
              label={strings["freeTrial"]}
              noBorder
            />
          </div>
        </div>
      </div>
    );
  }
}

MenuDesktop.propTypes = {
  locale: PropTypes.string.isRequired,
  /*eslint-disable react/forbid-prop-types*/
  strings: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
  blueTheme: PropTypes.bool,
};

MenuDesktop.defaultProps = {
  blueTheme: false,
};

export default addI18nContextConsumer(i18n)(MenuDesktop);
